// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MosquesList from "./components/MosquesList";
import MosqueDetails from "./components/MosqueDetails";
import ParkingInfo from './components/ParkingInfo'; // Make sure to import your ParkingInfo component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MosquesList />} />
        <Route path="/mosque/:id" element={<MosqueDetails />} />
        <Route path="/parking/:id" element={<ParkingInfo />} /> {/* Add this line */}
      </Routes>
    </Router>
  );
}

export default App;
