import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Link } from 'react-router-dom';
import './MosquesList.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { format } from 'date-fns';

const MosquesList = () => {
  const [mosques, setMosques] = useState([]);
  const [error, setError] = useState(null);
  const [nextSalaah, setNextSalaah] = useState('');
  const [loading, setLoading] = useState(true);

 const fetchMosques = async () => {
  try {
    const { data: mosquesData, error: mosquesError } = await supabase
      .from('mosques')
      .select('id, name, address, location_url');

    if (mosquesError) throw mosquesError;

    // Sort mosques by name in alphabetical order (A-Z)
    const sortedMosques = mosquesData.sort((a, b) => a.name.localeCompare(b.name));

    setMosques(sortedMosques);
    setLoading(false);
  } catch (err) {
    setError(err);
    setLoading(false);
  }
};


  const fetchPrayerTimes = async () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const { data: prayerTimesData, error: prayerTimesError } = await supabase
      .from('prayertimes')
      .select('fajar_jamaat, zuhr_jamaat, asr_jamaat, maghrib, isha_jamaat, mosque_id, date')
      .eq('date', currentDate);

    if (prayerTimesError) {
      console.error('Error fetching prayer times:', prayerTimesError);
      return;
    }

    const updatedMosques = mosques.map((mosque) => {
      const times = prayerTimesData.find((pt) => pt.mosque_id === mosque.id);
      return {
        ...mosque,
        PrayerTimes: times || {},
      };
    });

    setMosques(updatedMosques);
    updateNextSalaah(updatedMosques);
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);

    return new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Europe/London',
    }).format(date);
  };

  const updateNextSalaah = (mosques) => {
    const now = new Date();
    let nextPrayer = null;

    mosques.forEach((mosque) => {
      const prayerTimes = mosque.PrayerTimes || {};
      const prayerList = [
        { name: 'Fajr', time: prayerTimes.fajar_jamaat },
        { name: 'Zohar', time: prayerTimes.zuhr_jamaat },
        { name: 'Asr', time: prayerTimes.asr_jamaat },
        { name: 'Maghrib', time: prayerTimes.maghrib },
        { name: 'Eisha', time: prayerTimes.isha_jamaat },
      ];

      const upcomingPrayer = prayerList.find((prayer) => {
        if (!prayer.time) return false;
        const [hours, minutes] = prayer.time.split(':');
        const prayerDate = new Date();
        prayerDate.setUTCHours(hours, minutes);
        return prayerDate > now;
      });

      if (upcomingPrayer && (!nextPrayer || upcomingPrayer.time < nextPrayer.time)) {
        nextPrayer = upcomingPrayer;
      }
    });

    if (nextPrayer) {
      setNextSalaah(`The Next Salaah is: ${nextPrayer.name}`);
    } else {
      setNextSalaah('No next Salaah available');
    }
  };

  useEffect(() => {
    fetchMosques();
  }, []);

  useEffect(() => {
    if (mosques.length > 0) {
      fetchPrayerTimes();
    }
  }, [mosques]);

  return (
    <div className="mosques-list">
      <h1 className="list-title">Mosques List</h1>
      <p className="Next-Salah">{nextSalaah}</p>
      {error && <p className="error-message">Error fetching mosques: {error.message}</p>}
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <table className="mosques-table">
          <thead>
            <tr>
              <th>Mosque</th>
              <th>Next Prayer Time</th>
            </tr>
          </thead>
          <tbody>
            {mosques.map((mosque) => {
              const prayerTimes = mosque.PrayerTimes || {};
              const prayerList = [
                { name: 'Fajr', time: prayerTimes.fajar_jamaat },
                { name: 'Zohar', time: prayerTimes.zuhr_jamaat },
                { name: 'Asr', time: prayerTimes.asr_jamaat },
                { name: 'Maghrib', time: prayerTimes.maghrib },
                { name: 'Eisha', time: prayerTimes.isha_jamaat },
              ];

              const nextPrayer = prayerList.find((prayer) => {
                if (!prayer.time) return false;
                const [hours, minutes] = prayer.time.split(':');
                const prayerDate = new Date();
                prayerDate.setUTCHours(hours, minutes);
                return prayerDate > new Date();
              });

              return (
                <tr key={mosque.id}>
                  <td>
                    <div className="mosque-info">
                      <a
                        href={mosque.location_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="direction-icon"
                        title="Get Directions"
                      >
                        <FaMapMarkerAlt />
                      </a>
                      <Link to={`/mosque/${mosque.id}`} className="mosque-link">
                        {mosque.name}
                      </Link>
                    </div>
                  </td>
                  <td>{nextPrayer ? formatTime(nextPrayer.time) : ''}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MosquesList;
