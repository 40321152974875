import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { format, parseISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faHome  } from "@fortawesome/free-solid-svg-icons";
import "./MosqueDetails.css";

const MosqueDetails = () => {
  const { id } = useParams();
  const [mosque, setMosque] = useState(null);
  const [parkingInfo, setParkingInfo] = useState(null);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const navigate = useNavigate();

  const fetchMosqueDetails = async () => {
    try {
      const { data: mosqueData, error: mosqueError } = await supabase
        .from("mosques")
        .select(
          `
          id, 
          name, 
          address, 
          location_url,
          prayertimes (
            id,
            mosque_id,
            date,
            fajar_start,
            fajar_jamaat,
            zuhr_start,
            zuhr_jamaat,
            asr_start,
            asr_jamaat,
            maghrib,
            isha_start,
            isha_jamaat
          )
        `
        )
        .eq("id", id);

      if (mosqueError) throw mosqueError;
      setMosque(mosqueData[0]);

      const { data: parkingData, error: parkingError } = await supabase
        .from("parking")
        .select(
          "onsite_parking, disable_bays, off_street_parking, road_name, address, distance_to_mosque"
        )
        .eq("mosque_id", id);

      if (parkingError) throw parkingError;
      setParkingInfo(parkingData[0]);
    } catch (err) {
      setError(err.message);
    }
  };

  const formatDate = (dateString) => format(parseISO(dateString), "dd-MM-yyyy");

  const formatTime = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);

    return new Intl.DateTimeFormat("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Europe/London",
    }).format(date);
  };

  const updateTime = () => {
    const now = new Date();
    const ukTime = new Intl.DateTimeFormat("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Europe/London",
    }).format(now);
    setCurrentTime(ukTime);
    setCurrentDate(format(now, "yyyy-MM-dd"));
  };

  useEffect(() => {
    fetchMosqueDetails();
    updateTime();
    const interval = setInterval(updateTime, 60000);

    return () => clearInterval(interval);
  }, [id]);

  const handleParkingClick = () => {
    navigate(`/parking/${id}`);
  };
  const handleHomeClick = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div className="mosque-details">
      <div className="home-icon" onClick={handleHomeClick}>
        <FontAwesomeIcon icon={faHome} />
      </div>
      {error && (
        <p className="error-message">Error fetching mosque details: {error}</p>
      )}
      {mosque ? (
        <div className="details-container">
          <h1 className="mosque-name">{mosque.name}</h1>
          <p className="current-time">Current Time: {currentTime}</p>
          <p className="mosque-address">{mosque.address}</p>
          {/* <button onClick={handleParkingClick} className="parking-button">
            <FontAwesomeIcon icon={faCar} className="parking-icon" />
          </button> */}
          <h2 className="parking-info-title">Parking Information</h2>

          {parkingInfo ? (
            <div className="parking-info">
              <p>
                <strong>Onsite Parking:</strong>{" "}
                {parkingInfo.onsite_parking &&
                parkingInfo.onsite_parking !== "Not Available" ? (
                  parkingInfo.onsite_parking
                ) : (
                  <span className="unavailable">Not Available</span>
                )}
              </p>
              <p>
                <strong>Disable Bays:</strong>{" "}
                {parkingInfo.disable_bays &&
                parkingInfo.disable_bays !== "Not Available" ? (
                  parkingInfo.disable_bays
                ) : (
                  <span className="unavailable">Not Available</span>
                )}
              </p>
              <p>
                <strong>Off Street Parking:</strong> {parkingInfo.road_name} -{" "}
                {parkingInfo.address}. Masjid is{" "}
                {parkingInfo.distance_to_mosque} minutes walk away.
              </p>
            </div>
          ) : (
            <p>No parking information available</p>
          )}

          <h2 className="prayer-times-title">Prayer Times</h2>
          <table className="prayer-times-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Fajr Start</th>
                <th>Fajr Jamaat</th>
                <th>Zuhr Start</th>
                <th>Zuhr Jamaat</th>
                <th>Asr Start</th>
                <th>Asr Jamaat</th>
                <th>Maghrib</th>
                <th>Isha Start</th>
                <th>Isha Jamaat</th>
              </tr>
            </thead>
            <tbody>
              {mosque.prayertimes &&
                mosque.prayertimes.map((prayer) => (
                  <tr
                    key={prayer.date}
                    className={prayer.date === currentDate ? "highlight" : ""}
                  >
                    <td className="date-column">{formatDate(prayer.date)}</td>
                    <td>{formatTime(prayer.fajar_start)}</td>
                    <td>{formatTime(prayer.fajar_jamaat)}</td>
                    <td>{formatTime(prayer.zuhr_start)}</td>
                    <td>{formatTime(prayer.zuhr_jamaat)}</td>
                    <td>{formatTime(prayer.asr_start)}</td>
                    <td>{formatTime(prayer.asr_jamaat)}</td>
                    <td>{formatTime(prayer.maghrib)}</td>
                    <td>{formatTime(prayer.isha_start)}</td>
                    <td>{formatTime(prayer.isha_jamaat)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading mosque details...</p>
      )}
    </div>
  );
};

export default MosqueDetails;
