import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import './ParkingInfo.css';

const ParkingInfo = () => {
  const { id } = useParams();
  const [parkingInstructions, setParkingInstructions] = useState([]);
  const [mosqueName, setMosqueName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading

  // Fetch the parking info and mosque name
  const fetchParkingInfo = async () => {
    setLoading(true); // Set loading to true before fetching
    const { data: mosqueData, error: mosqueError } = await supabase
      .from('mosques')
      .select('name')
      .eq('id', id)
      .single();

    if (mosqueError) {
      setError(mosqueError);
      setLoading(false); // Stop loading
      return;
    }

    setMosqueName(mosqueData.name);

    const { data: parkingData, error: parkingError } = await supabase
      .from('mosque_parking')
      .select('instruction')
      .eq('mosque_id', id);

    if (parkingError) {
      setError(parkingError);
    } else {
      setParkingInstructions(parkingData);
    }
    
    setLoading(false); // Stop loading after data fetching
  };

  useEffect(() => {
    fetchParkingInfo();
  }, [id]);

  return (
    <div className="parking-info">
      {error && <p className="error-message">Error fetching parking info: {error.message}</p>}
      {mosqueName && <h1 className="mosque-name">{mosqueName}</h1>}
      <div className="parking-info-container">
        <h2 className="info-title">Parking Information</h2>
        {loading ? ( // Conditional rendering for loading state
          <p>Loading instructions...</p>
        ) : parkingInstructions.length > 0 ? (
          <ol className="instruction-list">
            {parkingInstructions.map((instruction) => (
              <li key={instruction.id} className="instruction-item">
                {instruction.instruction}
              </li>
            ))}
          </ol>
        ) : (
          <p className="no-instructions">No instructions for this mosque.</p>
        )}
      </div>
    </div>
  );
};

export default ParkingInfo;
